.page-item .page-link {
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
}

.page-item:last-child, .page-item:first-child {
  .page-link{
    border-radius: 50%;
  }
}



