@import '../../App.scss';

.header-stack {
  height: 40px;
}

.header-search-container {
  //height: 72px;
}
.dropdown-toggle.nav-link{
  color: white;
}


