h6 + small {
  cursor: pointer;
}

small + small {
  cursor: pointer;
}

small + img {
  width: 72px;
  height: 25px;
  object-fit: cover;
}

.whatsapp-div {
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  margin-top: -30px;
}
